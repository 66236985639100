.links {
    font-size: 14px;
}
.link-hidden {
    display: none;
}
.show-all-links {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}